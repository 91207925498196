import { useContext } from "react";
import { AuthContext } from "../core/AuthContext";
import { IAuthContextValue } from "../__types";

/**
 * Helper function to get the useContext hook
 * already configured with the AuthContext
 */
export function useAuthContext(): IAuthContextValue {
	return useContext(AuthContext) as IAuthContextValue;
}
