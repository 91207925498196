import { gameAuthenticate, IGameAuthInfo } from "@/api";
import { getSessionStorage, setSessionStorage } from "@/helpers";
import React, { useState, useEffect } from "react";
import { GameContext } from "./GameContext";
import { IGameContextValue } from "./_types";

export interface IGameContextProviderProps {
	children: React.ReactNode;
}

export function GameContextProvider(props: IGameContextProviderProps) {
	const [authInfo, setAuthInfo] = useState<IGameAuthInfo>(() => getSessionStorage("auth-info", {}));
	React.useEffect(() => {
		setSessionStorage("auth-info", authInfo);
	}, [authInfo]);

	const onAuthenticate = async (email: string, passcode: string) => {
		const authResult = await gameAuthenticate(email, passcode);
		setAuthInfo({
			gameId: authResult.id,
			userActorId: authResult.actorId,
			userEmail: authResult.email,
		});
	};
	// Prepare the value to set as context
	const value: IGameContextValue = {
		authInfo,
		authenticate: onAuthenticate,
	};

	// Return the configured provider
	return <GameContext.Provider value={value}>{props.children}</GameContext.Provider>;
}
