import React from "react";
import "./src/styles/global.css";
import { GameContextProvider } from "@/contexts";
import { AuthProvider } from "@/components/Auth";

export const wrapRootElement = ({ element }) => {
	return (
		<AuthProvider>
			<GameContextProvider>
				{element}
			</GameContextProvider>
		</AuthProvider>
	)
}