import { navigate } from "gatsby";
import React, { useLayoutEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";

interface IRedirectIfLoggedInProps {
	children: React.ReactNode;
	homePath?: string;
}

/**
 * Wrapper that will redirect a user if already logged in. Use
 * this for pages that the user should not see if already logged in
 *
 * This should be wrapped with the AuthProvider component
 * for it to work
 */
export function RedirectIfLoggedIn(props: IRedirectIfLoggedInProps) {
	const { isLoggedIn, returnTo, userInfo } = useAuthContext();

	// Get out of here if already logged in
	useLayoutEffect(() => {
		if (isLoggedIn && userInfo.email) {
			// Determine if there is a returnTo url
			const currentUrl = new URL(window.location.href);
			// const returnTo = decodeURIComponent(currentUrl.searchParams.get("returnTo") || "");
			if (returnTo) {
				const decodedReturn = decodeURIComponent(returnTo);
				navigate(decodedReturn);
			} else {
				console.info("the email", { userInfo });
				if (userInfo.email.includes("+zazar")) {
					navigate("/game-picker");
				} else {
					navigate("/game");
				}
			}
			// const returnPath = returnTo && returnTo.startsWith("/") ? returnTo : props.homePath || "/";

			// Go to the requested page
			// navigate(returnPath);
		}
	}, [isLoggedIn, returnTo, userInfo]);

	// Render the view
	if (!isLoggedIn) {
		return <React.Fragment>{props.children}</React.Fragment>;
	}
	return null;
}
