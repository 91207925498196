import React from "react";
import { IGameContextValue } from "./_types";

/**
 * Return the Game Context
 */
const GameContext = React.createContext<IGameContextValue>({
	authenticate: async () => {
		return;
	},
});
export { GameContext };
