import React, { useState, useEffect } from "react";
import { Hub, HubCapsule } from "@aws-amplify/core";
import { CognitoUser } from "@aws-amplify/auth";
import { IAuthContextValue, IUserInfo } from "../__types";
import { AuthApi, AuthApiConfig, AuthContext } from "../core";

export interface IAuthProviderProps {
	children: React.ReactNode;
}

/**
 * Export an AuthProvider that can be used to wrap all
 * of the other components
 *
 * @param props
 */
export function AuthProvider(props: IAuthProviderProps) {
	const [isLoad, setIsLoad] = useState<boolean>(false);
	const [returnUrl, setReturnUrl] = useState<string>();
	const [user, _setUser] = useState<CognitoUser | null>(null);
	const [userInfo, _setUserInfo] = useState<IUserInfo>({
		username: "",
		displayName: "",
		email: "",
		emailVerified: false,
		name: "",
		lastName: "",
		phone: "",
		provider: "aws_cognito",
	});

	// Determine the local storage key
	const localStorageKey = `CognitoIdentityServiceProvider.${AuthApiConfig.userPoolWebClientId}.LastAuthUser`;

	// The first time that it loads get the current user information
	useEffect(() => {
		refreshUser()
			.then(() => setIsLoad(true))
			.catch(() => setIsLoad(true));
	}, []);

	// Effect to add a listener to localStorage so that
	// the user information is updated when the key changes
	useEffect(() => {
		const listener = (ev: StorageEvent) => {
			if (ev.key === localStorageKey) {
				if (ev.newValue !== ev.oldValue) {
					refreshUser();
				}
			}
		};
		addEventListener("storage", listener);
		return () => {
			removeEventListener("storage", listener);
		};
	}, []);

	// Effect to add a listener to the AuthApi so that
	// we can detect changes in the session
	useEffect(() => {
		const listener = (p: HubCapsule) => {
			if (p.payload.event === "customOAuthState") {
				const data = p.payload.data;
				if (data) {
					setReturnUrl(p.payload.data);
				}
			}
			refreshUser();
		};
		Hub.listen("auth", listener);
		return () => {
			Hub.remove("auth", listener);
		};
	}, []);

	// Effect to add custom hub listener as "custom:auth"
	useEffect(() => {
		const listener = () => {
			refreshUser();
		};
		Hub.listen("custom:auth", listener);
		return () => {
			Hub.listen("custom:auth", listener);
		};
	});

	// Convert the current user info to an object
	const refreshUser = async (): Promise<void> => {
		try {
			const user = await AuthApi.currentAuthenticatedUser();
			const session = await AuthApi.currentSession();
         
			const idTokenPayload = session.getIdToken().payload || {};
			const idProvider = getIdentityProvider(idTokenPayload);
			const greetingParts = [];
			if (idTokenPayload.name) {
				greetingParts.push(idTokenPayload.name);
			}
			if (idTokenPayload.family_name && idProvider === "aws_cognito") {
				greetingParts.push(idTokenPayload.family_name);
			}
			const displayName = greetingParts.length ? greetingParts.join(" ").trim() : idTokenPayload.email;
			_setUser(user);
			_setUserInfo({
				username: idTokenPayload.sub,
				email: idTokenPayload.email,
				emailVerified: idTokenPayload.email_verified,
				displayName: displayName || idTokenPayload.email,
				name: idTokenPayload.name || idTokenPayload.email,
				lastName: idTokenPayload.family_name || idTokenPayload.email,
				phone: idTokenPayload.phone_number || "",
				provider: idProvider,
			});
		} catch (ex) {
			_setUser(null);
			_setUserInfo({
				username: "",
				displayName: "",
				email: "",
				emailVerified: false,
				name: "",
				lastName: "",
				phone: ",",
				provider: "aws_cognito",
			});
		}
	};

	const isLoggedIn = (() => {
		if (typeof localStorage === "undefined") {
			return false;
		}
		if (typeof localStorage.getItem(localStorageKey) !== "string") {
			return false;
		}
		return true;
	})();

	// Prepare the value to set as context
	const value: IAuthContextValue = {
		isLoggedIn: isLoggedIn,
		user: user as any, // as any so that it allows null but intellisense does not require it
		userInfo: userInfo, // as any so that it allows null but intellisense does not require it
		returnTo: returnUrl,
		setReturnUrl,
	};

	// Return the configured provider
	return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}
function getIdentityProvider(idTokenPayload: { [key: string]: any }): "aws_cognito" | "google" | "facebook" {
	const identities: any[] = idTokenPayload["identities"];
	if (!identities || !identities.length) {
		return "aws_cognito";
	}
	const googleIndex = identities.findIndex((id: any) => {
		return id.providerName === "Google";
	});
	if (googleIndex >= 0) {
		return "google";
	}
	const facebookIndex = identities.findIndex((id: any) => {
		id.providerName === "Facebook";
	});
	if (facebookIndex >= 0) {
		return "facebook";
	}
	return "aws_cognito";
}
