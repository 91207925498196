// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-game-picker-tsx": () => import("./../../../src/pages/game-picker.tsx" /* webpackChunkName: "component---src-pages-game-picker-tsx" */),
  "component---src-pages-game-tsx": () => import("./../../../src/pages/game.tsx" /* webpackChunkName: "component---src-pages-game-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-team-management-tsx": () => import("./../../../src/pages/team-management.tsx" /* webpackChunkName: "component---src-pages-team-management-tsx" */),
  "component---src-pages-why-tsx": () => import("./../../../src/pages/why.tsx" /* webpackChunkName: "component---src-pages-why-tsx" */)
}

