import { ExcelFile, IExcelFileRequest } from "./_types";
import ExcelJS from "exceljs";

export function removeSessionStorage(key: string) {
	try {
		window.sessionStorage.removeItem(key);
	} catch (e) {
		// catch possible errors:
		// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
	}
}
export function setSessionStorage(key: string, value: any) {
	try {
		window.sessionStorage.setItem(key, JSON.stringify(value));
	} catch (e) {
		// catch possible errors:
		// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
	}
}

export function getSessionStorage(key: string, initialValue: any) {
	try {
		const value = window.sessionStorage.getItem(key);
		return value ? JSON.parse(value) : initialValue;
	} catch (e) {
		// if error, return initial value
		return initialValue;
	}
}

export default async function createExcelFile(request: IExcelFileRequest): Promise<ExcelFile> {
	const { columns, rows, filename } = request;

	const wb = new ExcelJS.Workbook();
	const ws = wb.addWorksheet();

	ws.columns = columns.map((c) => ({ width: c.width || 9 }));

	ws.addRow(
		columns.map((c) => c.name),
		"n"
	);

	let rowCounter = 0;
	const totalRowValues: (number | undefined)[] = [];
	for (const r of rows) {
		ws.addRow(r);
		if (rowCounter === rows.length - 1) {
			let shouldSum = false;
			r.forEach((rowValue, index) => {
				if (index < 3) {
					totalRowValues.push(undefined);
				} else if (shouldSum) {
					const sum = +rowValue + +r[index - 1];
					totalRowValues.push(sum);
					shouldSum = false;
				} else {
					totalRowValues.push(undefined);
					shouldSum = true;
				}
			});
		}
		rowCounter++;
	}

	ws.addRow(totalRowValues);

	const buffer = await wb.xlsx.writeBuffer();
	return {
		file: new Blob([buffer]),
		filename: `${filename}.xlsx`,
	};
}
