import { CognitoHostedUIIdentityProvider, Auth } from "@aws-amplify/auth";

const AuthApiConfig = {
	region: process.env.GATSBY_GAMERS_COGNITO_POOL_REGION_ID || "xx-xx-xx",
	userPoolId: process.env.GATSBY_GAMERS_COGNITO_POOL_ID || "pool_id",
	userPoolWebClientId: process.env.GATSBY_GAMERS_COGNITO_POOL_CLIENT_ID || "client_id",
};

Auth.configure(AuthApiConfig);

export { Auth as AuthApi, AuthApiConfig, CognitoHostedUIIdentityProvider };
