import axios from "axios";
import { GameGlobalVarsRecord, IAction, IEngineAction, IGameAction, IGameActor, IGameAuthResponse, IGameConfig, IGamerAccount, IGameRound, IRoundUsedActions, IUseAction } from "./_types";
import { Auth } from "@aws-amplify/auth";

export * from "./_types";

const API_ENDPOINT = "https://api.dev.r-manager.io";
export async function gameAuthenticate(email: string, passcode: string) {
	const result = await axios.post(`${API_ENDPOINT}/game-manager/auth`, {
		passcode,
		email,
	});
	return result.data as IGameAuthResponse;
}

export async function getEngineActions(actorId: string, gameId: string, roundId: string): Promise<IEngineAction[]> {
	const result = await axios.post(`${API_ENDPOINT}/game-engine/actions/by-actor`, {
		actorId,
		gameId,
		roundId,
	});
	return result.data.items as IEngineAction[];
}

export async function getEngineActionsAffect(actorId: string, gameId: string, roundId: string): Promise<IEngineAction[]> {
	const result = await axios.post(`${API_ENDPOINT}/game-engine/actions/affect-to-actor`, {
		actorId,
		gameId,
		roundId,
	});
	return result.data.items as IEngineAction[];
}

export async function getGames(): Promise<IGameConfig[]> {
	const result = await axios.get(`${API_ENDPOINT}/game-manager/games/list`);
	return result.data as IGameConfig[];
}

export async function getActions(actorId: string, gameId: string): Promise<IAction[]> {
	const result = await axios.get(`${API_ENDPOINT}/game-manager/games/${gameId}/actions/${actorId}`);
	return result.data.items as IAction[];
}

export async function getActionsAffected(actorId: string, gameId: string): Promise<IAction[]> {
	const result = await axios.get(`${API_ENDPOINT}/game-manager/games/${gameId}/actions-affected/${actorId}`);
	return result.data.items as IAction[];
}

export async function getGamerAccount(accountId: string): Promise<IGamerAccount> {
	const result = await axios.get(`${API_ENDPOINT}/gamers-manager/accounts/${accountId}`);
	return result.data as IGamerAccount;
}

export async function getGameConfig(gameId: string): Promise<IGameConfig> {
	const result = await axios.get(`${API_ENDPOINT}/game-manager/games/${gameId}/config`);
	return result.data as IGameConfig;
}

export async function getGameGlobalVars(gameId: string): Promise<GameGlobalVarsRecord> {
	const result = await axios.get(`${API_ENDPOINT}/game-manager/games/${gameId}/record/GlobalVars`);
	return result.data as GameGlobalVarsRecord;
}

export async function getGameActors(gameId: string): Promise<IGameActor[]> {
	const result = await axios.get(`${API_ENDPOINT}/game-manager/games/${gameId}/record/Actors`);
	return result.data.items as IGameActor[];
}

export async function getGameCurrentRound(gameId: string): Promise<IGameRound> {
	const result = await axios.get(`${API_ENDPOINT}/game-engine/rounds/${gameId}/current`);
	return result.data as IGameRound;
}

export async function useAction(gameId: string, roundId: string, actorId: string, actionId: string): Promise<IUseAction> {
	const creds = await Auth.currentSession();
	const result = await axios.post(
		`${API_ENDPOINT}/game-engine/actions/use`,
		{
			actionId,
			actorId,
			gameId,
			roundId,
		},
		{
			headers: { "Content-Type": "application/json", Authorization: `Bearer ${creds.getIdToken().getJwtToken()}` },
		}
	);
	return result.data as IUseAction;
}

export async function getUsedActions(gameId: string, actorId?: string): Promise<IRoundUsedActions[]> {
	const creds = await Auth.currentSession();
	const result = await axios.post(
		`${API_ENDPOINT}/game-engine/actions/used`,
		{
			actorId,
			gameId,
		},
		{
			headers: { "Content-Type": "application/json", Authorization: `Bearer ${creds.getIdToken().getJwtToken()}` },
		}
	);
	return result.data.items as IRoundUsedActions[];
}

export async function getUsedAffectedActions(gameId: string, roundId: string, actorId: string): Promise<IEngineAction[]> {
	const result = await axios.post(`${API_ENDPOINT}/game-engine/actions/affected-to-actor`, {
		actorId,
		gameId,
		roundId,
	});
	return result.data as IEngineAction[];
}
